import LeftSection from "../components/LoginComponents/LeftSection";
import RigthSection from "../components/LoginComponents/RightSection";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { login } from "../services/AuthApi";
import { isTokenExpired } from "../services/AuthApi";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!isTokenExpired()) {
      history.push("/");
      window.dispatchEvent(new Event("popstate"));
    }
  }, [history]);

  const handleLogin = async (e) => {
    e.preventDefault();

    setError("");
    if (email === "" || password === "") {
      setError("Invalid Email or password");
      return;
    }

    try {
      setLoading(true);
      const response = await login({ email, password });
      localStorage.setItem("AccessToken", response.data.access);
      history.push("/");
    } catch (error) {
      console.error("Login failed", error);
      setError(
        error.response?.data?.message[0] ||
          "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="flex justify-between">
        <LeftSection
          email={email}
          setEmail={setEmail}
          password={password}
          handleLogin={handleLogin}
          setPassword={setPassword}
          loading={loading}
          error={error}
        />
        <RigthSection />
      </div>
    </div>
  );
};

export default Login;
