import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { getCategoriesCount } from "../../services/api";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
  const [categoriesCount, setCategoriesCount] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategoriesCount = async () => {
      try {
        setLoading(true);
        const response = await getCategoriesCount();
        setCategoriesCount(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoriesCount();
  }, []);

  const data = {
    labels: categoriesCount.map((category) => category.category),
    datasets: [
      {
        label: "Categories Count",
        data: categoriesCount.map((category) => category.count),
        backgroundColor: categoriesCount.map((category) => category.color),
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 14,
            weight: 400,
            color: "#475467",
            height: 20,
          },
          boxWidth: 8,
          boxHeight: 8,
        },
      },
      tooltip: {
        bodyFont: {
          size: 14,
          weight: 400,
        },
        titleFont: {
          size: 14,
          weight: 400,
        },
      },
    },
  };

  if (isLoading) {
    return (
      <div className="container-fluid bg-opacity-50 bg-gray-100 inset-0 flex items-center justify-center w-100">
        <div className="bg-white p-5 rounded-lg shadow-lg">
          <svg
            className="animate-spin h-8 w-8 text-gray-500 mx-auto"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <Doughnut
      style={{ height: "400px", width: "500px" }}
      data={data}
      options={options}
    />
  );
};

export default DoughnutChart;
