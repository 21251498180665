import SideBar from "../components/DashboardComponents/SideBar";
import ProfileHeader from "../components/ProfileComponents/Header";
import ProfileStats from "../components/ProfileComponents/ProfileStats";
import ProductForm from "../components/ProfileComponents/ProductForm";
import ProfileInfoForm from "../components/ProfileComponents/ProfileInfoForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { useContext } from "react";
import { getAgentInfo, getCustomerInfo } from "../services/AuthApi";

import {
  fetchSingleLoanApplication,
  getAgentsRevenueCalculations,
  getUserRevenueCalculations,
} from "../services/api";
import { isTokenExpired } from "../services/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoanApplicationContext } from "../LoanApplication";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AgentsProfileInfoForm from "../components/DashboardComponents/AgentsPageComponents/AgentProfileComponents/ProfileInfoForm";
import AgentsProductForm from "../components/DashboardComponents/AgentsPageComponents/AgentProfileComponents/ProductForm";

const Profile = () => {
  const { id } = useParams();
  const [loanApplication, setLoanApplication] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [revenueData, setRevenueData] = useState(null);
  const history = useHistory();
  const { setLoanApplicationId } = useContext(LoanApplicationContext);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [agentInfo, setAgentInfo] = useState(null);
  const location = useLocation();
  const userType = location.pathname.split("/")[1];

  useEffect(() => {
    setLoanApplicationId(id);
  }, [id, setLoanApplicationId]);

  useEffect(() => {
    const isUserNotLogin = isTokenExpired();
    if (isUserNotLogin) {
      history.push("/login");
      window.dispatchEvent(new Event("popstate"));
    } else {
      userType === "customer" ? 
      getCustomer() : getAgent()
    }
  }, []);

  const getCustomer = async () => {
    try {
      const response = await getCustomerInfo(id);
      
      setCustomerInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch products", error);
      setLoading(false);
    }
  };

  const getAgent = async () => {
    try {
      const response = await getAgentInfo(id);
      
      setAgentInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch products", error);
      setLoading(false);
    }
  };
  

  const getRevenueCalculation = async () => {
    try {
      const response =  userType === "customer" ? await getUserRevenueCalculations(id) : await getAgentsRevenueCalculations(id);      
      setRevenueData(response.data);
    } catch (error) {
      console.log("Error", error);
    } finally {
    }
  };

  useEffect(() => {
    getRevenueCalculation();
  }, [customerInfo]);

  return (
    <div className="flex p-0 ">
      <div className="flex flex-col w-full " style={{ marginLeft: "4rem" }}>
          {
          userType === "customer" ? 
            <>
              <ProfileHeader
                id={customerInfo ? id : null}
                isprofile={true}
                firstName={customerInfo ? customerInfo.first_name : null}
                lastName={customerInfo ? customerInfo.last_name : null}
                loanId={customerInfo ? id : null}
              />
              {error ? <p className="bf-red-500">{{ error }}</p> : ""}
                <div className="max-w-[1440px] mx-auto">
                  <ProfileStats revenueData={revenueData} userType={userType} />
                  <ProductForm userId={customerInfo ? id : null} userType={userType}  />
                  <ProfileInfoForm
                    userInfo={customerInfo}
                    loading={loading}
                    setLoading={setLoading}
                    refresh={getCustomer}
                  />
                </div>
            </>
            :
            <>
              <ProfileHeader
                id={agentInfo ? id : null}
                isprofile={true}
                firstName={agentInfo ? agentInfo.first_name : null}
                lastName={agentInfo ? agentInfo.last_name : null}
                loanId={agentInfo ? id : null}
              />
              {error ? <p className="bf-red-500">{{ error }}</p> : ""}
              <div className="max-w-[1440px] mx-auto">
                <ProfileStats revenueData={revenueData} userType={userType} />
                <AgentsProductForm userId={agentInfo ? id : null} userType={userType}  />
                <AgentsProfileInfoForm
                  userInfo={agentInfo}
                  loading={loading}
                  setLoading={setLoading}
                  refresh={getCustomer}
                />
              </div>
            </>
          }
      </div>
      <SideBar page={userType}/>
      <ToastContainer
        type="success"
        promise="true"
        position="top-right"
        autoClose="3000"
        theme="colored"
      />
    </div>
  );
};

export default Profile;
