import baseUrl from "../../services/config";

const Profile = ({ profileImage }) => {
  return (
    <img
      class="h-12 w-12 flex-none rounded-full bg-gray-50"
      src={
        profileImage
          ? `${baseUrl}/${profileImage}`
          : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
      }
      alt=""
    ></img>
  );
};
export default Profile;
