import { dateFormat } from "../../../utils/dateFormat";
import { AgentsPagination } from "./AgentsPagination";
import AgentsTableFilters from "./AgentsTableFilters";

const AgentsTable = ({
  Agents,
  previousLink,
  nextLink,
  totalPages,
  fetchAgents,
  queryStr,
  setQueryStr,
  loading,
  setLoading,
}) => {
  const handleQueryStringChange = (query) => {
    setQueryStr(query);
  };

  const handleChangePage = (value, name) => {
    setQueryStr((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="relative overflow-x-auto sm:rounded-lg rounded-lg w-[1176px] border border-[#EAECF0] mt-10">
        <AgentsTableFilters
          setQuery={handleQueryStringChange}
          queryStr={queryStr}
          setQueryStr={setQueryStr}
        />
        {loading ? (
          <div className="container-fluid bg-opacity-50 bg-gray-100  inset-0 flex items-center justify-center">
            <div className="bg-white p-5 rounded-lg shadow-lg">
              <svg
                className="animate-spin h-8 w-8 text-gray-500 mx-auto"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
                ></path>
              </svg>
            </div>
          </div>
        ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-3 dark:text-gray-400 box-shadow">
            <thead className="text-xs text-gray-500 bg-[#F9FAFB]  dark:text-gray-400 border border-[#EAECF0]">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Lifetime Revenue
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Inception Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Customers
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Agent ID
                </th>
              </tr>
            </thead>
            <tbody>
              {Agents.map((data, index) => (
                <tr key={data?.id} className="bg-white border-b d">
                  <a href={`/agent/profile/${data?.id}`}>
                    <th
                      scope="row"
                      className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap hover:bg-gray-50 rounded-lg cursor-pointer"
                    >
                      <img
                        className="w-10 h-10 rounded-full"
                        src={
                          data?.profile_picture
                            ? data?.profile_picture
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                        }
                        alt="user profile"
                      />
                      <div className="ps-3 text-[#0439d7]">
                        <div className="text-base">
                          {data?.first_name || "N/A"} {data?.last_name || "N/A"}
                        </div>
                        <div className="text-[14px] font-[400] text-[#475467] hover:underline">
                          {data?.phone_number || "N/A"}
                        </div>
                      </div>
                    </th>
                  </a>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      ${parseFloat(data?.lifetime_revenue).toFixed(2) || "0.00"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      {data?.inception_date.split("T")[0] || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      {data?.customer_count || "0"}
                    </div>
                  </td>

                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      cov-{data?.id}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="mt-[24px]">
        <AgentsPagination
          currentPage={queryStr?.page}
          totalPages={totalPages}
          nextLink={nextLink}
          previousLink={previousLink}
          setPage={handleChangePage}
          queryString={(value) => fetchAgents(value)}
        />
      </div>
    </>
  );
};

export default AgentsTable;
