import React, { useEffect } from "react";

export function AgentsPagination({ currentPage, totalPages, setPage }) {
  const [active, setActive] = React.useState(currentPage);

  useEffect(() => {
    setActive(currentPage);
  }, [currentPage]);

  const handleNextClick = () => {
    if (active < totalPages) {
      const nextPage = active + 1;
      setActive(nextPage);
      setPage(nextPage, "page");
    }
  };

  const handlePrevClick = () => {
    if (active > 1) {
      const prevPage = active - 1;
      setActive(prevPage);
      setPage(prevPage, "page");
    }
  };

  const handlePageClick = (page) => {
    setPage(page, "page");
    setActive(page);
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 6;
    const maxPagesAtEdges = 2;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(renderPageButton(i));
      }
    } else {
      for (let i = 1; i <= maxPagesAtEdges; i++) {
        pages.push(renderPageButton(i));
      }

      if (active > maxPagesAtEdges + Math.ceil(maxPagesToShow / 2)) {
        pages.push(renderEllipsis("left"));
      }

      const startPage = Math.max(
        Math.min(
          active - Math.floor(maxPagesToShow / 2),
          totalPages - maxPagesToShow + 1
        ),
        maxPagesAtEdges + 1
      );
      const endPage = Math.min(
        startPage + maxPagesToShow - 1,
        totalPages - maxPagesAtEdges
      );

      for (let i = startPage; i <= endPage; i++) {
        pages.push(renderPageButton(i));
      }

      if (endPage < totalPages - maxPagesAtEdges) {
        pages.push(renderEllipsis("right"));
      }

      for (let i = totalPages - maxPagesAtEdges + 1; i <= totalPages; i++) {
        pages.push(renderPageButton(i));
      }
    }

    return pages;
  };

  const renderPageButton = (page) => (
    <button
      key={page}
      onClick={() => handlePageClick(page)}
      className={`relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle   text-xs font-medium uppercase ${
        active === page
          ? "bg-slate-200 text-black"
          : "text-gray-900 hover:bg-gray-900/10 active:bg-slate-600/20"
      } transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
      type="button"
    >
      <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        {page}
      </span>
    </button>
  );

  const renderEllipsis = (key) => (
    <span
      key={`ellipsis-${key}`}
      className="h-10 w-10 flex items-center justify-center"
    >
      ...
    </span>
  );

  return (
    <div>
  <div className="border-b border-gray-200 w-full h-px"></div>
  <div
       className="flex items-center gap-4 flex justify-between mt-[20px]">
        <button
          disabled={active === 1}
          onClick={handlePrevClick}
          className="flex items-center gap-2 px-3 py-2 ml-0.5  text-[14px] text-[#182230] font-[500] text-center   align-middle transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true"
            className="w-4 h-4 mt-0.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            ></path>
          </svg>
          Previous
        </button>
        <div className="flex items-center gap-2 text-[14px] text-[#182230] font-[500]">
          {renderPagination()}
        </div>
        <button
          disabled={active === totalPages}
          onClick={handleNextClick}
          className="flex items-center gap-2 px-3 py-2  text-[14px] mr-0.5 text-[#182230] font-[500] text-center   align-middle transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
        >
          Next
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true"
            className="w-4 h-4 mt-0.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
