import { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";

const AgentsTableFilters = ({ setQueryStr, queryStr }) => {

  const statusDropdownRef = useRef(null);
  const sourceDropdownRef = useRef(null);

  const handleQueryStringChange = (value, name) => {
    setQueryStr((prevState) => ({
      ...prevState,
      ["page"]: 1,
      [name]: value.toLowerCase(),
    }));
    
  };

  const debouncedHandleQueryStringChange = debounce((e) => {
    handleQueryStringChange(e?.target?.value, "search");
  }, 1000);

  const handleClearFilters = () => {
    setQueryStr({
      page: 1,
      search: "",
    });
    document.getElementById("table-search-users").value = "";
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
      }
      if (
        sourceDropdownRef.current &&
        !sourceDropdownRef.current.contains(event.target)
      ) {
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center p-5 justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 bg-white">
      <div className="flex gap-4 justify-end">
        <div>
          <div>
            <p className="text-[#344054] font-[500] my-2">
              Search for agent or agent ID{" "}
            </p>
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              onChange={(e) => debouncedHandleQueryStringChange(e)}
              className="block p-3 ps-10 text-sm h-[44px] text-gray-900 input-focus border border-[#D0D5DD] rounded w-80 "
              placeholder="Search"
            />
          </div>
        </div>

      </div>
      <div className="flex flex-col justify-end pt-[2rem]">
        <button
          onClick={handleClearFilters}
          className="bg-transparent border-[#D0D5DD] mt-1 hover:bg-[#D0D5DD] h-[44px] text-dark-700 hover:text-dark font-[500] py-2 px-4 border hover:border-transparent rounded"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default AgentsTableFilters;
