import image from "./images/RightSection.png";

const RigthSection = () => {
  return (
    <div className="w-[100vw] md:block bg-transparent h-[95%] hidden md:flex md:justify-end">
      <img className="w-[90%] p-[24px] h-screen bg-transparent" src={image} />
    </div>
  );
};

export default RigthSection;
