import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";

const ProfileHeader = (props) => {
  const location = useLocation();
  const userType = location.pathname.split("/")[1];
  
  return (
    <header
      style={{ height: "max-content", zIndex: 22 }}
      className="bg-white sticky top-0 w-full max-h-full h-auto"
    >
      <div className="mx-6 max-w-7xl py-6">
        <div className="text-[30px] font-[500] tracking-tight text-[#101828]">
          {props.firstName} {props.lastName}
        </div>
      </div>

      <div className="mx-6 border-b border-[#EAECF0]">
        <ul className="flex space-x-5">
          <Link to={`/${userType}/profile/${props.loanId}`}>
            <li
              className={`${
                props.isprofile
                  ? "border-b-2 border-[#0439d7] text-[#0439d7] w-auto font-[500] text-[14px]"
                  : "text-[#0439d7] text-[#667085] w-auto font-[500] text-[14px] "
              }`}
            >
              <div className="mb-[12px]">Profile</div>
            </li>
          </Link>
          <Link to={`/${userType}/chat/${props.id}`}>
            <li
              className={`${
                !props.isprofile
                  ? "border-b-2 border-[#0439d7] text-[#0439d7] w-auto font-[500] text-[14px] "
                  : "text-[#0439d7] text-[#667085] w-auto font-[500] text-[14px] "
              }`}
            >
              <div className="mb-[12px]">Communication</div>
            </li>
          </Link>
        </ul>
      </div>
    </header>
  );
};

export default ProfileHeader;
