const Header = () => {
  return (
    <header
      style={{ height: "max-content", zIndex: "11" }}
      class="bg-white sticky top-0 w-full  max-h-full h-auto"
    >
      <div class="mx-6 max-w-7xl  py-6 ">
        <h1 class="text-3xl font-[500] tracking-tight text-gray-900">
          Overview
        </h1>
      </div>
    </header>
  );
};

export default Header;
