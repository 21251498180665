import React, { createContext, useState } from "react";

export const LoanApplicationContext = createContext();

export const LoanApplicationProvider = ({ children }) => {
  const [loanApplicationId, setLoanApplicationId] = useState(null);

  return (
    <LoanApplicationContext.Provider
      value={{ loanApplicationId, setLoanApplicationId }}
    >
      {children}
    </LoanApplicationContext.Provider>
  );
};
