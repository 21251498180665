import SideBar from "../components/DashboardComponents/SideBar";
import ProfileHeader from "../components/ProfileComponents/Header";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleLoanApplication } from "../services/api";
import { isTokenExpired } from "../services/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChatComponent from "../components/ChatComponent/ChatComponent";
import { LoanApplicationContext } from "../LoanApplication";
import { getUserChat } from "../services/api";
import { useContext } from "react";
import { getCustomerInfo } from "../services/AuthApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Chat = () => {
  const { id } = useParams();
  const [loanApplication, setLoanApplication] = useState(null);
  const [messageList, setMessageList] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestInProgess, setRequestInProgess] = useState(false);
  const history = useHistory();
  const { loanApplicationId } = useContext(LoanApplicationContext);
  const [userInfo, setUserInfo] = useState(null);
  const location = useLocation();
  const userType = location.pathname.split("/")[1];

  useEffect(() => {
    const isUserNotLogin = isTokenExpired();
    if (isUserNotLogin) {
      history.push("/login");
      window.dispatchEvent(new Event("popstate"));
    } else {
      getLoanApplication();
    }
  }, []);

  const getLoanApplication = async () => {
    try {
      const response = await fetchSingleLoanApplication(id);
      setLoanApplication(response.data);
    } catch (error) {
      console.error("Failed to fetch products", error);
      setError("Failed to fetch products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getChats = async (id, limit) => {
    try {
      const response = await getUserChat(id, limit);
      if (messageList && messageList.messages.length > 0) {
        setMessageList({
          ...messageList,
          messages: [...response.data.messages.slice().reverse()],
        });
      } else {
        let a = response.data.messages.slice().reverse();
        response.data.messages = a;
        setMessageList(response.data);
        setTimeout(function () {
          document.getElementById("chat-container").scrollTop =
            document.getElementById("chat-container")?.scrollHeight;
        }, 500);
      }
      setRequestInProgess(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCustomer();
  }, []);
  const getCustomer = async () => {
    try {
      const response = await getCustomerInfo(id);
      setUserInfo(response.data);
    } catch (error) {
      console.error("Failed to fetch products", error);
    }
  };

  return (
    <div className="flex p-0 w-full">
      <SideBar page={userType} />
      <div className="flex flex-col w-full" style={{ marginLeft: "4rem" }}>
        <ProfileHeader
          id={loanApplication ? loanApplication.user.id : null}
          firstName={userInfo ? userInfo.first_name : null}
          lastName={userInfo ? userInfo.last_name : null}
          loanId={loanApplicationId}
        />
        <ChatComponent
          messagelist={messageList}
          fetchChats={getChats}
          loading={loading}
          requestInProgess={requestInProgess}
          setRequestInProgess={setRequestInProgess}
          setMessagelist={setMessageList}
          userInfo={userInfo ? userInfo : null}
        />
      </div>
    </div>
  );
};

export default Chat;
