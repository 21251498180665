import { useEffect, useState } from "react";
import {
  fetchCustomersProducts,
  updateCustomerProductsStatus,
} from "../../services/api";
import { toast } from "react-toastify";
import "./index.css";

const ProductForm = ({ userId }) => {
  const [productsList, setProducts] = useState(null);
  const [approved, setApproved] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async (intitialLoading) => {
    if (userId) {
      try {
        if (intitialLoading) {
          setLoading(true);
        }
        const response = await fetchCustomersProducts(userId);
        setLoading(false);
        setProducts(response.data);
        setApproved(
          response.data.map((product) => product.status === "approved")
        );
      } catch (error) {
        console.error("Error fetching customer products:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {}, []);

  const handleInputChange = async (id, index) => {
    const newStatus = approved[index] ? "rejected" : "approved";
    const updatedApproved = [...approved];
    updatedApproved[index] = !approved[index];
    setApproved(updatedApproved);

    try {
      await updateCustomerProductsStatus(id, newStatus);

      toast(`Status Updated`, {
        type: "success",
      });
    } catch (error) {
      console.error(`Error updating status for product ${id}:`, error);
      toast(`Error updating status for product please reload the page`, {
        type: "error",
      });
      updatedApproved[index] = !updatedApproved[index];
      setApproved(updatedApproved);
      setLoading(false);
    }
    fetchData(false);
  };
  const downloadFile = async (url) => {
    setLoading(true);
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const response = await fetch(url);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  };

  useEffect(() => {
    fetchData(true);
  }, [userId]);

  return (
    <div className="container-fluid px-5">
      <div className="text-dark px-4 mt-[24px] flex items-center mb-[20px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="37"
          viewBox="0 0 48 37"
          fill="none"
        >
          <path
            d="M18.0232 28.125V21.75C18.0232 21.1125 17.5357 20.625 16.8982 20.625C16.2607 20.625 15.7732 21.1125 15.7732 21.75V28.125H13.8982C13.2607 28.125 12.7732 28.6125 12.7732 29.25C12.7732 29.8875 13.2607 30.375 13.8982 30.375H24.3982C25.0357 30.375 25.5232 29.8875 25.5232 29.25C25.5232 28.6125 25.0357 28.125 24.3982 28.125H22.8982V21.75C22.8982 21.1125 22.4107 20.625 21.7732 20.625C21.1357 20.625 20.6482 21.1125 20.6482 21.75V28.125H18.0232Z"
            fill="black"
          />
          <path
            d="M46.1482 33.75H44.5357C44.6107 33.525 44.6482 33.2625 44.6482 33V3C44.6482 1.35 43.2982 0 41.6482 0H23.6482C23.0107 0 22.5232 0.4875 22.5232 1.125V8.25C22.5232 8.8875 23.0107 9.375 23.6482 9.375H29.6482C30.2857 9.375 30.7732 8.8875 30.7732 8.25V2.25H41.6482C42.0607 2.25 42.3982 2.5875 42.3982 3V33C42.3982 33.4125 42.0607 33.75 41.6482 33.75H11.6482C11.2357 33.75 10.8982 33.4125 10.8982 33V3C10.8982 2.5875 11.2357 2.25 11.6482 2.25H18.7732C19.4107 2.25 19.8982 1.7625 19.8982 1.125C19.8982 0.4875 19.4107 0 18.7732 0H11.6482C9.99819 0 8.64819 1.35 8.64819 3V33C8.64819 33.2625 8.68569 33.525 8.76069 33.75H7.14819C6.51069 33.75 6.02319 34.2375 6.02319 34.875C6.02319 35.5125 6.51069 36 7.14819 36H46.1482C46.7857 36 47.2732 35.5125 47.2732 34.875C47.2732 34.2375 46.7857 33.75 46.1482 33.75Z"
            fill="black"
          />
          <path
            d="M1.14825 36.0371C1.78241 36.0371 2.2965 35.523 2.2965 34.8889C2.2965 34.2547 1.78241 33.7406 1.14825 33.7406C0.514089 33.7406 0 34.2547 0 34.8889C0 35.523 0.514089 36.0371 1.14825 36.0371Z"
            fill="black"
          />
        </svg>
        <div className="ml-2">
          <h2 className="text-[18px] font-[500] text-[#101828] flex items-center">
            Products
          </h2>
          <p className=" text-[14px]  text-[#475467]  font-[400]">
            Customer products under a loan tenure or rejected
          </p>
        </div>
      </div>
      <hr />
      {loading ? (
        <div className="container-fluid bg-opacity-50 bg-gray-100 h-full min-h-[500px] w-full min-w-[1440px] inset-0 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <svg
              className="animate-spin h-8 w-8 text-gray-500 mx-auto"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="w-full">
          {productsList?.map((product, index) => (
            <div className="flex gap-[32px] items-center my-8" key={index}>
              <div className="w-[280px]">
                <label
                  htmlFor="email"
                  className="block mb-2 text-[14px] font-[500] satoshi-bold brand-text w-fit"
                >
                  {product.product_brand} (Brand)
                </label>
                <label
                  htmlFor="email"
                  className="block mb-2 text-[14px] font-[400] sub-brand-text w-fit"
                >
                  {product.submitted_at}
                </label>
              </div>
              <div className=" w-[512px]  relative">
                <input
                  value={`${product.product.replace(/^\S+\s*/, "").trim()} ($${
                    product.product_price
                  })`}
                  type="text"
                  id="product"
                  style={{ height: "44px" }}
                  disabled
                  aria-describedby="helper-text-explanation"
                  className="bg-[#F9FAFB] box-shadow border border-[#D0D5DD] text-[16px] font-[400] text-[#667085] rounded-[4px] focus:ring-blue-500 focus:border-blue-500 block w-full pl-[10px] pr-[185px] py-[14px]"
                />
                <div className="absolute inset-y-0 right-[100px] leading-[20px] text-[#475467] text-[12px] pl-3 flex items-center pointer-events-none">
                  {product.tenure?.replace("months", "installments")}
                </div>

                <div className="absolute inset-y-0 right-3 flex items-center  pointer-events-none">
                  <span
                    className={`inline-flex items-center rounded-xl px-2 py-1 text-xs font-medium ring-1 ring-inset
            ${
              product.status === "pending"
                ? "bg-yellow-100 text-yellow-600 ring-yellow-600/20"
                : ""
            }
            ${
              product.status === "approved"
                ? "bg-green-100 text-green-800 ring-green-600/20"
                : ""
            }
            ${
              product.status === "rejected"
                ? "bg-red-200 text-red-800 ring-red-600/20"
                : ""
            }
          `}
                  >
                    {product.status === "approved" ? (
                      <svg
                        className="h-3 w-3 text-green-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : product.status === "pending" ? (
                      <svg
                        className="h-3 w-3 text-[#B93815]"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                      </svg>
                    ) : product.status === "rejected" ? (
                      <svg
                        class="h-3 w-3 text-[#F04438]"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                        <line x1="18" y1="6" x2="6" y2="18" />{" "}
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    ) : (
                      ""
                    )}

                    {product.status.charAt(0).toUpperCase() +
                      product.status.slice(1)}
                  </span>
                </div>
              </div>
              {/* <div className=" flex justify-between"> */}
              {/* <div className=""> */}
              <div className="w-[200px]">
                <label className="flex cursor-pointer">
                  <input
                    type="checkbox"
                    checked={approved[index]}
                    onChange={() => handleInputChange(product.id, index)}
                    className="sr-only peer"
                  />
                  <div className=" mt-0.5 relative w-[36px] h-[21px] rounded-full hover:bg-[#0439d7] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] bg-[#B42318] after:absolute after:top-[2.5px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all dark:border-gray-600 peer-checked:bg-[#067647]"></div>
                  <span className="ml-3 text-[14px] font-medium text-black-900 dark:text-black-300">
                    {approved[index] ? "Approved" : "Rejected"}
                    <p className="sub-brand-text  text-[14px] font-[400]">
                      Status from SSB
                    </p>
                  </span>
                </label>
              </div>
              <div className="">
                <div className="mt-1 whitespace-nowrap">
                  <button
                    onClick={() => downloadFile(product.user_chat_pdf)}
                    className="text-sm text-blue-500 download-form-text font-[500] hover:underline cursor-pointer disabled:text-slate-400"
                    disabled={product.user_chat_pdf ? false : true}
                  >
                    Download Form
                  </button>
                </div>
              </div>
            </div>
            // </div>
          ))}

          <hr />
        </div>
      )}
    </div>
  );
};

export default ProductForm;
