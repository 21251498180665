import React from "react"; // Ensure React is imported

import { parseISO, format } from "date-fns";

const OutGoingMessages = (props) => {
  const lines = props.text.split("\n");

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4"></div>
      <div className="col-span-8">
        <div className="flex items-center justify-between mt-1">
          <div className="text-[14px] font-[500] text-[#344054]">You</div>
          <div className="text-[12px] text-[#475467] font-[400]">
            {props.date ? format(parseISO(props.date), "EEEE hh:mma") : "Now"}
          </div>
        </div>
        <div className="border mt-1 bg-[#0439d7] text-white p-3 text-left rounded-l-[8px] rounded-r-none rounded-br-[8px] rounded-bl-[8px] border-slate-300">
          <div className="whitespace-pre-line max-w-full break-words overflow-wrap">
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line.split(/(\d+⃣)/g).map((part, subIndex) =>
                  part.match(/^\d+⃣$/) ? (
                    <div
                      key={subIndex}
                      className="inline-flex items-center justify-center p-1 w-5 h-5 text-sm font-[500] border border-[#a3a3a3] rounded-md bg-[#52525b] text-white shadow-lg"
                    >
                      {part.match(/^\d+/)[0]}
                    </div>
                  ) : (
                    part
                  )
                )}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutGoingMessages;
