import EmojiPicker from "emoji-picker-react";
import { useState } from "react";

const SendMessageInput = (props) => {
  const [showEmojis, setShowEmpjie] = useState(false);

  const handleEmojiClick = (event, emojiObject) => {
    props.setMessage(props.message + event.emoji);
    document.getElementById("message-input").value += event.emoji;
  };
  return (
    <div className="container w-full">
      <div className="contianer rounded-[8px] border border-slate-300 p-2 text-black-500">
        <div>
          <textarea
            onInput={(e) => props.setMessage(e.target.value)}
            className="w-full h-12 p-2 border-0 focus:border-0 box-shadow focus:outline-none rounded overflow-y-auto resize-none"
            placeholder="Send a message"
            id="message-input"
          ></textarea>
          <div className="w-full flex justify-end">
            <div className="relative">
              <div className={`${showEmojis ? "abolute" : "hidden"}`}>
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  style={{ position: "absolute", right: "2px", bottom: "30px" }}
                />
              </div>
            </div>
            <button onClick={() => setShowEmpjie(!showEmojis)}>
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.1665 19.6666C15.1665 19.6666 16.4165 21.3333 18.4998 21.3333C20.5832 21.3333 21.8332 19.6666 21.8332 19.6666M20.9998 15.5H21.0082M15.9998 15.5H16.0082M26.8332 18C26.8332 22.6023 23.1022 26.3333 18.4998 26.3333C13.8975 26.3333 10.1665 22.6023 10.1665 18C10.1665 13.3976 13.8975 9.66663 18.4998 9.66663C23.1022 9.66663 26.8332 13.3976 26.8332 18ZM21.4165 15.5C21.4165 15.7301 21.23 15.9166 20.9998 15.9166C20.7697 15.9166 20.5832 15.7301 20.5832 15.5C20.5832 15.2698 20.7697 15.0833 20.9998 15.0833C21.23 15.0833 21.4165 15.2698 21.4165 15.5ZM16.4165 15.5C16.4165 15.7301 16.23 15.9166 15.9998 15.9166C15.7697 15.9166 15.5832 15.7301 15.5832 15.5C15.5832 15.2698 15.7697 15.0833 15.9998 15.0833C16.23 15.0833 16.4165 15.2698 16.4165 15.5Z"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={props.onSendMessage}
              className="rounded-[8px] ml-[10px] bg-[#0439D7] hover:bg-blue-700 text-white p-2 px-4 "
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessageInput;
