import { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";

const TableFilters = ({ setQueryStr, queryStr }) => {
  const [isStatusDropDownOpen, setStatusDropDown] = useState(false);
  const [isSourceDropDownOpen, setSourceDropDownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const statusDropdownRef = useRef(null);
  const sourceDropdownRef = useRef(null);

  const handleQueryStringChange = (value, name, color = "") => {
    setQueryStr((prevState) => ({
      ...prevState,
      ["page"]: 1,
      [name]: value.toLowerCase(),
    }));
    if (color) {
      const svgElement = document.getElementById("svg-status");
      svgElement.setAttribute("fill", color);
    }

    setSourceDropDownOpen(false);
    setStatusDropDown(false);
  };

  const debouncedHandleQueryStringChange = debounce((e) => {
    handleQueryStringChange(e?.target?.value, "search");
  }, 1000);

  const handleClearFilters = () => {
    setQueryStr({
      page: 1,
      search: "",
      status: "",
      source: "",
    });
    document.getElementById("table-search-users").value = "";
    const svgElement = document.getElementById("svg-status");
    svgElement.setAttribute("fill", "green");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
        setStatusDropDown(false);
      }
      if (
        sourceDropdownRef.current &&
        !sourceDropdownRef.current.contains(event.target)
      ) {
        setSourceDropDownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center p-5 justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 bg-white">
      <div className="flex gap-4 justify-end">
        <div>
          <div>
            <p className="text-[#344054] font-[500] my-2">
              Search for customer or customer ID{" "}
            </p>
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              onChange={(e) => debouncedHandleQueryStringChange(e)}
              className="block p-3 ps-10 text-sm h-[44px] text-gray-900 input-focus border border-[#D0D5DD] rounded w-80 "
              placeholder="Search"
            />
          </div>
        </div>

        <div ref={statusDropdownRef}>
          <div className="flex flex-col">
            <div>
              <p className="text-[#344054] font-[500] my-2">Status</p>
            </div>
            <button
              style={{ width: "200px" }}
              onClick={() => setStatusDropDown(!isStatusDropDownOpen)}
              id="dropdownActionButton"
              data-dropdown-toggle="dropdownAction"
              className="inline-flex items-center justify-between text-gray-500 bg-white h-[44px] border border-[#D0D5DD] rounded input-focus text-sm p-[12px]"
              type="button"
            >
              <div className="flex gap-4 items-center">
                <svg
                  className="w-4 h-4"
                  id="svg-status"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="green"
                >
                  <circle cx="12" cy="12" r="6" />
                </svg>
                <span className="sr-only">Action button</span>
                {queryStr?.status || "Filter by Status"}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 20 20"
                fill="none"
                className="ms-2.5"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="dropdownAction"
            className={`absolute z-10 bg-white divide-y rounded-lg divide-gray-100 ml-[1px] w-[198px] h-[44px]  shadow  ${
              isStatusDropDownOpen ? `block` : `hidden`
            }`}
          >
            <ul
              className="py-1 text-sm text-black-700 bg-white box-shadow rounded-lg"
              aria-labelledby="dropdownActionButton"
            >
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={(e) =>
                    handleQueryStringChange("", "status", "green")
                  }
                >
                  All
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="px-4 py-2 flex items-center  hover:bg-gray-100"
                  onClick={(e) =>
                    handleQueryStringChange(
                      e.target.innerText,
                      "status",
                      "green"
                    )
                  }
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="green"
                  >
                    <circle cx="7" cy="12" r="6" />
                  </svg>
                  Approved
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="px-4 py-2 flex items-center hover:bg-gray-100"
                  onClick={(e) =>
                    handleQueryStringChange(
                      e.target.innerText,
                      "status",
                      "orange"
                    )
                  }
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="orange"
                  >
                    <circle cx="7" cy="12" r="6" />
                  </svg>
                  Pending
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="px-4 py-2 flex items-center hover:bg-gray-100"
                  onClick={(e) =>
                    handleQueryStringChange(e.target.innerText, "status", "red")
                  }
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="red"
                  >
                    <circle cx="7" cy="12" r="6" />
                  </svg>
                  Rejected
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div ref={sourceDropdownRef}>
          <div className="flex flex-col">
            <div>
              <p className="text-[#344054] font-[500] my-2">Source</p>
            </div>

            <button
              style={{ width: "200px" }}
              onClick={() => setSourceDropDownOpen(!isSourceDropDownOpen)}
              id="dropdownActionButton"
              data-dropdown-toggle="dropdownAction"
              className="inline-flex items-center justify-between input-focus text-gray-500 h-[44px] bg-white border border-[#D0D5DD] rounded text-sm p-[12px]"
              type="button"
            >
              <div className="flex gap-4">
                <span className="sr-only">Action button</span>
                {queryStr?.source || "All"}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 20 20"
                fill="none"
                className="ms-2.5"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <div
              id="dropdownAction"
              className={`z-20 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-[200px] mt-20 pt-3 ${
                isSourceDropDownOpen ? "block" : "hidden"
              }`}
            >
              <ul
                className="py-1 text-sm text-gray-700 dark:text-black-200"
                aria-labelledby="dropdownActionButton"
              >
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 hover:bg-gray-100 "
                    onClick={(e) => handleQueryStringChange("", "source")}
                  >
                    All
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 hover:bg-gray-100 "
                    onClick={(e) =>
                      handleQueryStringChange(e.target.innerText, "source")
                    }
                  >
                    Web
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 hover:bg-gray-100 d "
                    onClick={(e) =>
                      handleQueryStringChange(e.target.innerText, "source")
                    }
                  >
                    Whatsapp
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end pt-[2rem]">
        <button
          onClick={handleClearFilters}
          className="bg-transparent border-[#D0D5DD] mt-1 hover:bg-[#D0D5DD] h-[44px] text-dark-700 hover:text-dark font-[500] py-2 px-4 border hover:border-transparent rounded"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default TableFilters;
