import { useEffect, useState } from "react";
import ChartComponent from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import ApexChart from "./AquisitionChat";
import {
  fetchCustomersCount,
  getRevenueCalculations,
} from "../../services/api";

const Stats = () => {
  const [customerCount, setCustomerCount] = useState(null);
  const [isloading, setLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [revenueCalculation, setRevenueCalculation] = useState(null);

  useEffect(() => {
    getRevenueCalculation();
    getCustomersCount();
  }, []);

  const getCustomersCount = async () => {
    try {
      setCustomerLoading(true);
      const response = await fetchCustomersCount();
      setCustomerCount(response.data);
      setCustomerLoading(false);
    } catch (error) {
      console.log("Error", error);
      setCustomerLoading(false);
    } finally {
      setCustomerLoading(false);
    }
  };

  const getRevenueCalculation = async () => {
    try {
      setLoading(true);
      const response = await getRevenueCalculations();
      setRevenueCalculation(response.data);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid w-[1355px] m-auto grid-cols-2 gap-[24px] px-7 py-3">
        <div className="p-[24px] border border-gray-200 box-shadow rounded-lg h-full">
          {isloading ? (
            <div className="container-fluid bg-opacity-50 bg-gray-100 inset-0 flex items-center justify-center h-full">
              <div className="bg-white  rounded-lg shadow-lg">
                <svg
                  className="animate-spin h-8 w-8 text-gray-500"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <>
              <div className="h-[188px]">
                <div className="flex justify-between">
                  <div className="font-[500]">
                    <div className="bg-white  border border-grey p-[12px] h-[48px] items-center w-[48px] flex justify-center rounded-[10px]">
                      <svg
                        width="22"
                        height="12"
                        viewBox="0 0 22 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 1L13.1314 8.86863C12.7354 9.26465 12.5373 9.46265 12.309 9.53684C12.1082 9.6021 11.8918 9.6021 11.691 9.53684C11.4627 9.46265 11.2646 9.26465 10.8686 8.86863L8.13137 6.13137C7.73535 5.73535 7.53735 5.53735 7.30902 5.46316C7.10817 5.3979 6.89183 5.3979 6.69098 5.46316C6.46265 5.53735 6.26465 5.73535 5.86863 6.13137L1 11M21 1H14M21 1V8"
                          stroke="#344054"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="">
                    <ChartComponent
                      revenueCalculation={
                        revenueCalculation ? revenueCalculation : []
                      }
                    />
                  </div>
                </div>

                <div className="">
                  <div>
                    <div className="text-[#101828] text-[16px] mt-[8px] font-[500]">
                      Total revenue
                    </div>
                  </div>
                  <div className="mt-[13px] flex items-center justify-between">
                    <div>
                      <h1 className="text-3xl font-medium">
                        ${parseFloat(revenueCalculation?.current_month_revenue).toFixed(2)}
                      </h1>
                    </div>
                    <div className="flex justify-end items-center">
                      <svg
                        className="h-5 w-5 text-[#067647] mt-0.5"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <polyline points="3 17 9 11 13 15 21 7" />
                        <polyline points="14 7 21 7 21 14" />
                      </svg>
                      <p className="text-[#067647] font-[500] text-[14px] ml-1">
                        {revenueCalculation?.revenue_change_percentage}%
                      </p>
                      <p className="text-[#475467] font-[500] text-[14px] ml-1">
                        vs last month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="p-[24px] h-full border border-gray-200 box-shadow rounded-lg">
          {isloading ? (
            <div className="container-fluid bg-opacity-50 bg-gray-100 inset-0 flex items-center justify-center">
              <div className="bg-white  rounded-lg shadow-lg">
                <svg
                  className="animate-spin h-8 w-8 text-gray-500 mx-auto"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <>
            <div className="h-[188px]">
              <div className="flex justify-between">
                <div className="font-[500]">
                  <div className="bg-white border border-grey p-[12px] h-[48px] items-center w-[48px] flex justify-center rounded-[10px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16 18L18 20L22 16M12 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                        stroke="#344054"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="h-[110px] ">
                </div>
              </div>
          
              <div className="">
                <div>
                  <div className="text-[#101828] text-[16px] mt-[8px] font-[500]">
                    Total customers
                  </div>
                </div>
                <div className="mt-[13px] flex items-center justify-between">
                  <div>
                    <h1 className="text-3xl font-medium">
                      {customerCount?.current_month_count}
                    </h1>
                  </div>
                  <div className="flex justify-end items-center">
                    <svg
                      className="h-5 w-5 text-[#067647] mt-0.5"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <polyline points="3 17 9 11 13 15 21 7" />
                      <polyline points="14 7 21 7 21 14" />
                    </svg>
                    <p className="text-[#067647] font-[500] text-[14px] ml-1">
                      {customerCount?.percentage_increase}%
                    </p>
                    <p className="text-[#475467] font-[500] text-[14px] ml-1">
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
          
          )}
        </div>
      </div>
      <div
        className="flex px-6  mt-[12px] mx-auto"
        style={{ maxWidth: "1440px" }}
      >
        <div
          className="border p-[24px] border-gray-200 box-shadow rounded-lg flex"
          style={{ height: "370px", width: "370px" }}
        >
          <div className=" flex-col font-[500] flex justify-center w-full">
            <div className="text-[#101828] text-[16px] font-[500] mt-[24px]">
              Product breakdown
            </div>
            <DoughnutChart />
          </div>
        </div>
        <div
          className="border border-gray-200 box-shadow rounded-lg flex ml-[24px]"
          style={{ height: "370px", width: "908px" }}
        >
          <div className="p-[24px] font-[500] w-full h-full">
            <div className="flex justify-start   text-[#101828] text-[16px] font-[500]">
              Acquisitions
            </div>
            <ApexChart />
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
};

export default Stats;
