import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Chart, registerables } from "chart.js";
import { getRevenueCalculations } from "../../services/api";
import { format } from "date-fns";
import { getAcquisitionData } from "../../services/api";

Chart.register(...registerables);

const ChartComponent = ({ revenueCalculation }) => {
  const [loading, setLoading] = useState(false);
  const [dailyRevenue, setDailyRevenue] = useState(revenueCalculation);
  const [series, setSeries] = useState([
    {
      name: "Revenue",
      data: [],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      height: 150,
      type: "area",
      padding: 0, // No padding for the chart itself
      background: "transparent", // Make background transparent
      toolbar: {
        show: false, // Moved toolbar settings here
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["rgba(23, 178, 106, 1)"],
    },
    xaxis: {
      type: "text",
      categories: [],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    colors: ["rgba(228, 246, 247)"],
  });

  const processRevenueData = async () => {
    try {
      const data = dailyRevenue.daily_revenue;
      const categories = data.map((item) => item.date);
      const revenues = data.map((item) => parseFloat(item.revenue));

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories,
        },
      }));

      setSeries([
        {
          name: "Revenue",
          data: revenues,
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    processRevenueData();
  }, [dailyRevenue]);

  return (
    <div style={{ padding: 0, margin: 0 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="95"
        width="128"
      />
    </div>
  );
};

export default ChartComponent;
