import TableFilters from "./TableFilters";
import { DefaultPagination } from "./Pagination";

const Table = ({
  loanApplications,
  previousLink,
  nextLink,
  totalPages,
  fetchLoanApplication,
  queryStr,
  setQueryStr,
  loading,
  setLoading,
}) => {
  const handleQueryStringChange = (query) => {
    setQueryStr(query);
  };

  const handleChangePage = (value, name) => {
    setQueryStr((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const downloadFile = async (url) => {
    setLoading(true);
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const response = await fetch(url);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  };

  return (
    <>
      <div className="relative overflow-x-auto sm:rounded-lg rounded-lg border border-[#EAECF0] mt-10">
        <TableFilters
          setQuery={handleQueryStringChange}
          queryStr={queryStr}
          setQueryStr={setQueryStr}
        />
        {loading ? (
          <div className="container-fluid bg-opacity-50 bg-gray-100  inset-0 flex items-center justify-center">
            <div className="bg-white p-5 rounded-lg shadow-lg">
              <svg
                className="animate-spin h-8 w-8 text-gray-500 mx-auto"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
                ></path>
              </svg>
            </div>
          </div>
        ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-3 dark:text-gray-400 box-shadow">
            <thead className="text-xs text-gray-500 bg-[#F9FAFB]  dark:text-gray-400 border border-[#EAECF0]">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Inception Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Source
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Tenure
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Tenure Completion
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Cutomer ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-[#475467] text-[12px] satoshi-bold"
                  style={{ fontWeight: "500" }}
                >
                  Form
                </th>
              </tr>
            </thead>
            <tbody>
              {loanApplications.map((data, index) => (
                <tr key={data?.id} className="bg-white border-b d">
                  <a href={`/customer/profile/${data?.id}`}>
                    <th
                      scope="row"
                      className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap hover:bg-gray-50 rounded-lg cursor-pointer"
                    >
                      <img
                        className="w-10 h-10 rounded-full"
                        src={
                          data?.profile_image
                            ? data?.profile_image
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                        }
                        alt="user profile"
                      />
                      <div className="ps-3 text-[#0439d7]">
                        <div className="text-base">
                          {data?.first_name || "N/A"} {data?.last_name || "N/A"}
                        </div>
                        <div className="text-[14px] font-[400] text-[#475467] hover:underline">
                          {data?.whatsapp_phone_number?.split(":")[1] || "N/A"}
                        </div>
                      </div>
                    </th>
                  </a>
                  <td className="px-6 py-4">
                    {data?.latest_loan_application?.status.toLowerCase() ===
                    "pending" ? (
                      <span
                        className={`inline-flex gap-1 items-center rounded-xl bg-[#FEF6EE] px-2 py-1 text-xs font-medium text-[#B93815] ring-1 ring-inset ring-red-600/20`}
                      >
                        <svg
                          className="h-3 w-3 text-[#B93815]"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                        </svg>
                        {data?.latest_loan_application?.status
                          .charAt(0)
                          .toUpperCase() +
                          data?.latest_loan_application?.status.slice(1) ||
                          "N/A"}
                      </span>
                    ) : data?.latest_loan_application?.status.toLowerCase() ===
                      "approved" ? (
                      <span
                        className={`inline-flex gap-1 items-center text-[#067647] rounded-xl bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-[#ABEFC6]`}
                      >
                        <svg
                          className="h-3 w-3 text-[#067647]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        {data?.latest_loan_application?.status
                          .charAt(0)
                          .toUpperCase() +
                          data?.latest_loan_application?.status.slice(1) ||
                          "N/A"}
                      </span>
                    ) : data?.latest_loan_application?.status.toLowerCase() ===
                      "rejected" ? (
                      <span
                        className={`inline-flex gap-1 items-center rounded-xl bg-[#FEF3F2] px-2 py-1 text-xs font-medium text-[#B42318] ring-1 ring-inset ring-[#FECDCA]`}
                      >
                        <svg
                          className="h-3 w-3 text-[#F04438]"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          {" "}
                          <path stroke="none" d="M0 0h24v24H0z" />{" "}
                          <line x1="18" y1="6" x2="6" y2="18" />{" "}
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                        {data?.latest_loan_application?.status
                          .charAt(0)
                          .toUpperCase() +
                          data?.latest_loan_application?.status.slice(1)}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      {data?.latest_loan_application?.inception_date.split(
                        "T"
                      )[0] || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      {data?.source || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      {data?.latest_loan_application?.tenure || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4 w-64">
                    <div className="flex items-center gap-x-3 whitespace-nowrap">
                      <div
                        className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden "
                        role="progressbar"
                        aria-valuenow={
                          data?.latest_loan_application?.complete_progress
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="flex flex-col justify-center rounded-full overflow-hidden bg-[#0439D7] text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-[#0439d7]"
                          style={{
                            width: `${data?.latest_loan_application?.complete_progress}%`,
                          }}
                        ></div>
                      </div>
                      <div className="w-10 text-end">
                        <span className="text-[14px] font-[500] text-[#344054]">
                          {data?.latest_loan_application?.complete_progress}%
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4">
                    <div className="flex items-center text-[14px] font-[400] text-[#475467]">
                      COV-{data?.id}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        onClick={() =>
                          downloadFile(
                            data?.latest_loan_application?.user_chat_pdf
                          )
                        }
                        className="font-medium text-[#0439D7] text-[14px] font-[500] hover:underline cursor-pointer disabled:text-slate-400"
                        disabled={!data?.latest_loan_application?.user_chat_pdf}
                      >
                        Download
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="mt-[24px]">
        <DefaultPagination
          currentPage={queryStr?.page}
          totalPages={totalPages}
          nextLink={nextLink}
          previousLink={previousLink}
          setPage={handleChangePage}
          queryString={(value) => fetchLoanApplication(value)}
        />
      </div>
    </>
  );
};

export default Table;
