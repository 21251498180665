import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getAcquisitionData } from "../../services/api";

const ApexChart = () => {
  const [acquisitions, setAcquisitionData] = useState(null);
  const [activeTimeFrame, setActiveTimeFrame] = useState("12_months");
  const [isloading, setLoading] = useState(false);
  const [series, setSeries] = useState([
    {
      name: "Revenue",
      data: [],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["rgba(4, 57, 215, 1)"],
    },
    xaxis: {
      type: "text",
      categories: [],
    },
    tooltip: {
      bodyFont: {
        size: 12,
        weight: 400,
      },
      titleFont: {
        size: 12,
        weight: 400,
      },
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    colors: ["rgba(242, 232, 245, 1)"],
  });

  const fetchData = async (timeFrame) => {
    try {
      setLoading(true);
      setActiveTimeFrame(timeFrame);
      const response = await getAcquisitionData(timeFrame);
      response.data.reverse();
      const data = response.data;
      setAcquisitionData(data);
      setLoading(false);

      const categories = data.map((item) =>
        item.month ? item.month : item.hour ? item.hour : item.day
      );

      const revenues = data.map((item) => parseFloat(item.revenue));
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories,
        },
      }));

      setSeries([
        {
          name: "Revenue",
          data: revenues,
        },
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData("12_months");
  }, []);

  if (isloading) {
    return (
      <div className="container-fluid bg-opacity-50 bg-gray-100 inset-0 flex items-center justify-center h-[250px] w-100 ">
        <div className="bg-white p-5  rounded-lg shadow-lg">
          <svg
            className="animate-spin h-8 w-8 text-gray-500 mx-auto"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
            ></path>
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="filter-buttons font-[500] flex gap-4 ml-4 mt-3 relative mr-[33px]">
        {/* Colored line */}
        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#EAECF0] "></div>

        {/* Buttons */}
        <button
          className={`relative pb-2 ${
            activeTimeFrame === "12_months"
              ? "border-b-2 border-[#0439D7]"
              : " mb-[4px]"
          }`}
          onClick={() => fetchData("12_months")}
        >
          <span
            className={`${
              activeTimeFrame === "12_months"
                ? "text-[#0439D7] text-[14px] font-[500]"
                : "text-[#667085] text-[14px] font-[500]"
            }`}
          >
            12 months
          </span>
        </button>
        <button
          className={`relative pb-2 ${
            activeTimeFrame === "3_months"
              ? "border-b-2 border-[#0439D7]"
              : " mb-[4px]"
          }`}
          onClick={() => fetchData("3_months")}
        >
          <span
            className={`${
              activeTimeFrame === "3_months"
                ? "text-[#0439D7]"
                : "text-[#667085]"
            } text-[14px] font-[500]`}
          >
            3 months
          </span>
        </button>
        <button
          className={`relative pb-2 ${
            activeTimeFrame === "30_days"
              ? "border-b-2 border-[#0439D7]"
              : " mb-[4px]"
          }`}
          onClick={() => fetchData("30_days")}
        >
          <span
            className={`${
              activeTimeFrame === "30_days"
                ? "text-[#0439D7]"
                : "text-[#667085]"
            } text-[14px] font-[500]`}
          >
            30 days
          </span>
        </button>
        <button
          className={`relative pb-2 ${
            activeTimeFrame === "7_days"
              ? "border-b-2 border-[#0439D7]"
              : " mb-[4px]"
          }`}
          onClick={() => fetchData("7_days")}
        >
          <span
            className={`${
              activeTimeFrame === "7_days" ? "text-[#0439D7]" : "text-[#667085]"
            } text-[14px] font-[500]`}
          >
            7 days
          </span>
        </button>
        <button
          className={`relative pb-2 ${
            activeTimeFrame === "24_hours"
              ? "border-b-2 border-[#0439D7]"
              : " mb-[4px]"
          }`}
          onClick={() => fetchData("24_hours")}
        >
          <span
            className={`${
              activeTimeFrame === "24_hours"
                ? "text-[#0439D7]"
                : "text-[#667085]"
            } text-[14px] font-[500]`}
          >
            24 hours
          </span>
        </button>
      </div>

      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={250}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
